var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level mt-4"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.organization.name) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('ul', {
    staticClass: "nav nav-tabs"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: this.$route.name == 'trust.pengesahan.pendaftaran' ? 'active' : '',
    attrs: {
      "to": {
        name: 'trust.pengesahan.pendaftaran'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("verification-hibah-application")) + " ")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: this.$route.name == 'trust.pengesahan.pendaftaran-saksi' ? 'active' : '',
    attrs: {
      "to": {
        name: 'trust.pengesahan.pendaftaran-saksi'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("verification-hibah-witness")))])], 1)]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {}, [_c('router-view', {
    attrs: {
      "organization": _vm.organization,
      "userRole": _vm.userRole
    }
  })], 1)])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }